export default class MediaData {
  url: string;
  title?: string | null;
  description?: string | null;
  subtitles?: SubtitleData[] | null;

  constructor(params: {
    url: string,
    title?: string | null,
    description?: string | null,
    subtitles?: SubtitleData[] | null
  }) {
    this.url = params.url;
    this.title = params.title;
    this.description = params.description;
    this.subtitles = params.subtitles;
  }

  static fromJson(json: any): MediaData {
    return new MediaData(json);
  }
}

export class SubtitleData {
  url: string;
  name: string;
  language: string;

  constructor(params: {
    url: string,
    name: string,
    language: string,
  }) {
    this.url = params.url;
    this.name = params.name;
    this.language = params.language;
  }
}
