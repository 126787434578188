import { useEffect, useRef, useState } from "react";
import { Constants } from "../../misc/Constants";
import { ICradle } from "../../misc/Injections";
import DeviceData, { Capabilities } from "../../models/DeviceData";

export default interface ConnectViewModel {
  isPinPadFocus: boolean,
  codeLength: number,
  codeValue: string,
  isValidated: boolean,
  connect: () => void;
  updateCode: (key: string) => void;
  onPinPadFocus: (isFocus: boolean) => void;
}

export function ConnectViewModelFactory(
  { socketService, deviceService }: ICradle
): ConnectViewModel {
  const [isValidated, setValidated] = useState(false)
  const [codeValue, updateCode] = useState('')
  const [isPinPadFocus, setPinPadFocus] = useState(true);
  const codeLength = 4;

  let ipAddress = useRef<string | null>(null)
  let deviceName = useRef<string>('')

  useEffect(() => {
    const ipAddressSubs = deviceService.watchIpAdress().subscribe((value) => ipAddress.current = value);
    const deviceNameSubs = deviceService.watchDeviceName().subscribe((value) => deviceName.current = value);
    return () => {
      ipAddressSubs.unsubscribe()
      deviceNameSubs.unsubscribe()
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  useEffect(() => {
    setValidated(validateCode())
    window.addEventListener('keydown', handleNumPad);
    return () => {
      window.removeEventListener('keydown', handleNumPad)
    };
  });

  /**
   * Establishes a WebSocket connection with a device.
   * @returns {void}
   */
  function connect(): void {
    const deviceData = new DeviceData({
      name: deviceName.current,
      type: Constants.deviceType,
      volume: 1, // TODO: Need to know the volume
      capabilities: new Capabilities({
        hasPlayState: true,
        hasPlayPause: true,
        hasDurationPosition: true,
        hasSeek: true,
        hasSpeed: true,
        hasRepeat: true,
        hasVolume: true,
        hasAudio: true,
        hasSubtitle: true,
      }),
    });

    // Check if Browser mode
    if (Constants.isBrowser) {
      socketService.connect(`ws://${window.location.host}/receiver`, deviceData);
      return;
    }
    if (Constants.isDevMode) {
      socketService.connect(`ws://${codeValue}/receiver`, deviceData);
      return;
    }

    let portChar: string = codeValue.last()
    let ipSocket: string = codeValue.dropLast()
    if (codeValue.length !== codeLength || !ipAddress.current) {
      return;
    }
    ipSocket = replaceLastDigit(ipAddress.current, parseInt(ipSocket));
    const portNumber = portChar.repeat(4);
    const url = `ws://${ipSocket}:${portNumber}/receiver`;
    socketService.connect(url, deviceData);
  }

  /**
   * Handles the input from the number pad on the keyboard and updates the code value accordingly.
   * @param e - The keyboard event object.
   */
  function handleNumPad(e: KeyboardEvent) {
    if (!Constants.isTV) {
      return;
    }
    e.preventDefault();
    const keyCode = e.keyCode;

    // Handle up arrow key
    if(keyCode === 38 && !isPinPadFocus) {
      setPinPadFocus(true)
      return;
    }

    // Handle Enter key
    if(keyCode === 13 && !isPinPadFocus) {
      connect()
      return;
    }

    // Handle Backspace or Delete keys
    if (keyCode === 8 || keyCode === 46) {
      updateCode(codeValue.dropLast());
      return;
    }

    // Handle Numpad
    if (codeValue.length >= codeLength) {
      return;
    }

    let code = '';
    if (keyCode >= 48 && keyCode <= 57) {
      code = String.fromCharCode(keyCode);
      setPinPadFocus(false);
    } else if (keyCode >= 96 && keyCode <= 105) {
      code = String.fromCharCode(keyCode - 48);
      setPinPadFocus(false);
    }

    updateCode(codeValue + code);
  }

  function onPinPadFocus(isFocus: boolean) {
    setPinPadFocus(isFocus);
  }

  /**
   * Replaces the last component of an IP address with a new digit.
   * @param ipAddress - The IP address to update.
   * @param newDigit - The new digit to replace the last component of the IP address.
   * @returns The updated IP address.
   */
  function replaceLastDigit(ipAddress: string, newDigit: number): string {
    const components = ipAddress.split(".");
    components[components.length - 1] = String(newDigit);
    return components.join(".");
  }

  function validateCode(): boolean {
    // Check is TV mode or not
    if (Constants.isTV) {
      return codeValue.length === codeLength
    }
    // Define the regular expression for matching an IPv4 address
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}(:\d{1,5})?$/;
    return ipRegex.test(codeValue);
  }

  return {
    isPinPadFocus,
    codeLength,
    codeValue,
    isValidated,
    updateCode,
    connect,
    onPinPadFocus
  }
}