import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ScreenType } from '../../misc/Constants';
import container from '../../misc/Injections';
import ConnectView from '../connect/ConnectView';
import IdleView from '../idle/IdleView';
import PlayView from '../play/PlayView';

const RootView: React.FC = () => {
  const activeScreen = container.cradle.rootViewModel.activeScreen;
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!location.pathname.includes(activeScreen)) {
      navigate(activeScreen, { replace: activeScreen !== ScreenType.Play })
    }
  }, [activeScreen, location])

  return (
    <Routes>
      <Route path={ScreenType.Connect} element={<ConnectView />} />
      <Route path={ScreenType.Idle} element={<IdleView />} />
      <Route path={ScreenType.Play} element={<PlayView />} />
      <Route path="*" element={<Navigate to={ScreenType.Connect} replace />} />
    </Routes>
  );
};

export default RootView;
