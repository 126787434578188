//import { faCompress, faExpand } from "@fortawesome/free-solid-svg-icons";
//import { TVPlayer, TVPlayerButtonProps } from 'react-tv-player';
import container from '../../misc/Injections';
import './PlayView.css';

const PlayView: React.FC = () => {
  // const viewModel = container.cradle.playViewModel

  // const customButtons: TVPlayerButtonProps[] = [
  //   { action: "skipback" },
  //   { action: "playpause" },
  //   { action: "skipforward" },
  // ]

  // const customButtonsForWeb: TVPlayerButtonProps[] = [
  //   ...customButtons,
  //   {
  //     action: "custom",
  //     align: "right",
  //     label: viewModel.isFullScreen ? "Minimise" : "Full screen",
  //     faIcon: viewModel.isFullScreen ? faCompress : faExpand,
  //     onPress: () => viewModel.toggleFullScreen(),
  //   }
  // ]

  return (
    <span>Player</span>
    // viewModel.media && <TVPlayer
    //   playing
    //   hideControlsOnArrowUp={true}
    //   customButtons={viewModel.isTv ? customButtons : customButtonsForWeb}
    //   url={viewModel.media.url}
    //   title={viewModel.media.title}
    //   subTitle={viewModel.media.description}
    //   volume={viewModel.volume}
    //   playbackRate={viewModel.playbackRate}
    //   onBuffer={viewModel.notifyBuffer}
    //   onError={viewModel.notifyError}
    //   onEnded={viewModel.finishPlay}
    //   config={{
    //     file: {
    //       tracks: viewModel.media.subtitles?.map(subtitle => ({
    //         default: false,
    //         kind: 'subtitles',
    //         src: subtitle.url,
    //         label: subtitle.name,
    //         srcLang: subtitle.language,
    //       })) ?? [],
    //     },
    //   }}
    // />
  );
};

export default PlayView;
