import './IdleView.css';
import logo from '../../assets/images/logo_large.png';

const IdleView: React.FC = () => {
  return (
    <div className='content-container'>
      <img className='logo' src={logo}></img>
      <p className='secondary-color'>Please select video on aPlayer mobile app to start cast</p>
    </div>
  );
};

export default IdleView;
