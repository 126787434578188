export enum ConnectStatus {
  Disconnected = 'Disconnected',
  Connected = 'Connected',
  Failed = 'Failed',
}

export enum PlayState {
  Buffering = 'Buffering',
  Playing = 'Playing',
  Paused = 'Paused',
  Stopped = 'Stopped',
  Finished = 'Finished',
  Error = 'Error',
}

export enum ScreenType {
  Connect = 'Connect',
  Idle = 'Idle',
  Play = 'Play',
}

export enum DeviceType {
  WebOS = 'WebOS',
  Tizen = 'Tizen',
  Browser = 'Browser',
  DevMode = 'DevMode',
}

export class Constants {
  static deviceType = DeviceType.Tizen;

  static get isTV() {
    return Constants.deviceType === DeviceType.WebOS || Constants.deviceType === DeviceType.Tizen;
  }

  static get isBrowser() {
    return Constants.deviceType === DeviceType.Browser;
  }

  static get isDevMode() {
    return Constants.deviceType === DeviceType.DevMode;
  }
}
