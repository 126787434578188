import { asClass, asFunction, createContainer } from 'awilix';
import ConnectViewModel, { ConnectViewModelFactory } from '../presentation/connect/ConnectViewModel';
//import PlayViewModel, { PlayViewModelFactory } from '../presentation/play/PlayViewModel';
import RootViewModel, { RootViewModelFactory } from '../presentation/root/RootViewModel';
import SocketService, { SocketServiceImpl } from '../services/SocketService';
import DeviceService, { DeviceServiceImpl } from '../services/DeviceService';

// dependencies name
export interface ICradle {
  socketService: SocketService
  deviceService: DeviceService
  rootViewModel: RootViewModel
  //playViewModel: PlayViewModel
  connectViewModel: ConnectViewModel
}

// Creating a container
const container = createContainer<ICradle>()

// Registering dependencies
container.register({
  socketService: asClass(SocketServiceImpl).singleton(),
  deviceService: asClass(DeviceServiceImpl).singleton(),
  rootViewModel: asFunction(RootViewModelFactory),
  //playViewModel: asFunction(PlayViewModelFactory),
  connectViewModel: asFunction(ConnectViewModelFactory),
});

export default container