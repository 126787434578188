export default class ControlsData {
  isPaused?: boolean | null;
  stop?: boolean | null;
  seekTo?: number | null;
  speed?: number | null;
  volume?: number | null;
  repeat?: boolean | null;
  audio?: TrackData | null;
  subtitle?: TrackData | null;

  constructor(params: {
    isPaused?: boolean | null,
    stop?: boolean | null,
    seekTo?: number | null,
    speed?: number | null,
    volume?: number | null,
    repeat?: boolean | null,
    audio?: TrackData | null,
    subtitle?: TrackData | null
  }) {
    this.isPaused = params.isPaused;
    this.stop = params.stop;
    this.seekTo = params.seekTo;
    this.speed = params.speed;
    this.volume = params.volume;
    this.repeat = params.repeat;
    this.audio = params.audio;
    this.subtitle = params.subtitle;
  }
}

export class TrackData {
  name?: string | null;
  language?: string | null;

  constructor(params: {
    name?: string | null,
    language?: string | null,
  }) {
    this.name = params.name;
    this.language = params.language;
  }

  static fromJson(json: any): TrackData {
    return new TrackData(json);
  }
}