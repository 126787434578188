import container from '../../misc/Injections';
import './ConnectView.css';
import PinPad from './components/PinPad/PinPad';
import logo from '../../assets/images/logo.png';
import { Constants } from '../../misc/Constants';

const ConnectView: React.FC = () => {
  const viewModel = container.cradle.connectViewModel

  return (
    <div className='connect-container'>
      <div className='top'>
        <div className='brand'>
          <img src={logo} className='logo'></img>
          <span className='secondary-color'>aPlayer Receiver</span>
        </div>
        <div className='secondary-color'>
          https://aplayer.co
        </div>
      </div>
      <div className='center'>
        {Constants.isDevMode && <input className='connection-url'
          onChange={(e: any) => viewModel.updateCode(e.target.value)}
          placeholder='Enter Connection URL'
          type="text" />}
        {Constants.isTV && <PinPad
          isPinPadFocus={viewModel.isPinPadFocus}
          length={viewModel.codeLength}
          value={viewModel.codeValue}
          onValueChange={viewModel.updateCode}
          onPinPadFocus={viewModel.onPinPadFocus} />}
        <button
          className={ !viewModel.isPinPadFocus ? "btn-connect active" : "btn-connect"}
          disabled={!Constants.isBrowser && !viewModel.isValidated}
          onMouseOver={() => viewModel.isValidated && viewModel.onPinPadFocus(false)}
          onClick={viewModel.connect}>
          Connect
        </button>
      </div>
      <div className='bottom'>
        <div className='guideline secondary-color'>
          Please get pin code in aPlayer mobile<br />
          Make sure your TV and mobile device are on the same network
        </div>
        <div className='secondary-color'>
          Version 1.0.0
        </div>
      </div>
    </div>
  );
};

export default ConnectView;
