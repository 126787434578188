import { BehaviorSubject, Observable, Subject } from "rxjs";
import { ConnectStatus } from "../misc/Constants";
import ControlsData, { TrackData } from "../models/ControlsData";
import MediaData from "../models/MediaData";
import PlayingData from "../models/PlayingData";
import DeviceData from "../models/DeviceData";

export default interface SocketService {
  connect(socketUrl: string, deviceData: DeviceData | null): void;
  disconnect(stopVideo: boolean): void;

  watchConnect(): Observable<ConnectStatus>;
  watchMedia(): Observable<MediaData | null>;
  watchControls(): Observable<ControlsData>;

  sendPlayingData(data: PlayingData): void
  resetMediaData(): void
}

export class SocketServiceImpl implements SocketService {
  private clientSocket: WebSocket | null = null;
  private connectFlow = new BehaviorSubject<ConnectStatus>(ConnectStatus.Disconnected);
  private mediaFlow = new BehaviorSubject<MediaData | null>(null);
  private controlsFlow = new Subject<ControlsData>();

  private keyAction = "action"
  private keyData = "data"

  connect(socketUrl: string, deviceData: DeviceData) {
    this.clientSocket = new WebSocket(socketUrl);
    this.handleEvents(this.clientSocket, deviceData)
  }

  disconnect(keepVideo: boolean) {
    if (!keepVideo) {
      this.resetMediaData();
    }
    this.clientSocket?.close();
    this.clientSocket = null;
  }

  watchConnect(): Observable<ConnectStatus> {
    return this.connectFlow.asObservable();
  }

  watchMedia(): Observable<MediaData | null> {
    return this.mediaFlow.asObservable();
  }

  watchControls(): Observable<ControlsData> {
    return this.controlsFlow.asObservable();
  }

  sendPlayingData(data: PlayingData) {
    this.clientSocket?.send(`{"playingInfo": ${data.toJson()}}`);
  }

  resetMediaData() {
    this.mediaFlow.next(null);
  }

  private setConnectStatus(status: ConnectStatus) {
    this.connectFlow.next(status);
  }

  private handleEvents(socket: WebSocket, deviceData: DeviceData) {
    // Connection opened
    socket.addEventListener('open', () => {
      console.log('Connection opened')
      this.clientSocket?.send(`{"deviceInfo": ${deviceData.toJson()}}`)
      this.setConnectStatus(ConnectStatus.Connected)
    });

    // Connection closed
    socket.addEventListener('close', () => {
      console.log('Connection closed')
      this.setConnectStatus(ConnectStatus.Disconnected)
      this.clientSocket?.close();
      this.clientSocket = null;
    });

    // Connection error
    socket.addEventListener('error', (event) => {
      console.error('WebSocket error:', event)
      this.setConnectStatus(ConnectStatus.Failed)
    });

    // Listen for messages
    socket.addEventListener('message', (event) => {
      this.processMessage(event.data)
    });
    return socket
  }

  private processMessage(message: string) {
    let json = JSON.parse(message);
    switch (json[this.keyAction]) {
      case "disconnect":
        this.disconnect(json[this.keyData]["keepVideo"]);
        break;

      case "start":
        this.mediaFlow.next(MediaData.fromJson(json[this.keyData]));
        break;

      case "stop":
        this.controlsFlow.next(new ControlsData({ stop: true }))
        break;

      case "pause":
        this.controlsFlow.next(new ControlsData({ isPaused: json[this.keyData] }))
        break;

      case "seek":
        this.controlsFlow.next(new ControlsData({ seekTo: json[this.keyData] }))
        break;

      case "speed":
        this.controlsFlow.next(new ControlsData({ speed: json[this.keyData] }))
        break;

      case "volume":
        this.controlsFlow.next(new ControlsData({ volume: json[this.keyData] }))
        break;

      case "repeat":
        this.controlsFlow.next(new ControlsData({ repeat: json[this.keyData] }))
        break;

      case "audio":
        this.controlsFlow.next(new ControlsData({ audio: TrackData.fromJson(json[this.keyData]) }))
        break;

      case "subtitle":
        this.controlsFlow.next(new ControlsData({ subtitle: TrackData.fromJson(json[this.keyData]) }))
        break;
    }
  }
}