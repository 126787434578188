import React, { useEffect, useState }  from 'react';

import "./PinPad.css";
import { Backspace, Trash } from './Icon';
import { Constants } from '../../../../misc/Constants';

interface PinPadProps {
  isPinPadFocus: boolean;
  length: number;
  value: string;
  onValueChange: (value: string) => void;
  onPinPadFocus: (isFocus: boolean) => void;
}

const PinPad: React.FC<PinPadProps> = ({ isPinPadFocus, length, value, onValueChange, onPinPadFocus }) => {
  const deleteKey = 'd'
  const backKey = 'b'
  const buttonValues = [
    '1', '2', '3',
    '4', '5', '6',
    '7', '8', '9',
    deleteKey, '0', backKey
  ];

  const [position, setPosition] = useState(0);

  useEffect(() => {
    window.addEventListener("keydown", handleNumPad);

    return () => window.removeEventListener("keydown", handleNumPad);
  });

  function handleNumPad(e: KeyboardEvent) {
    if (!Constants.isTV || !isPinPadFocus) {
      return;
    }
    e.preventDefault();

    switch (e.keyCode) {
      //LEFT ARROW
      case 37:
        if(position === -1) break;
        else if (position === 0 || position === 3 || position === 6 || position === 9) break;
        else setPosition(position - 1);
        break;
      //RIGHT ARROW
      case 39:
        if(position === -1) break;
        else if (position === 2 || position === 5 || position === 8 || position === 11) break;
        else setPosition(position + 1);
        break;
      //UP ARROW
      case 38:
        if(position === -1) break;
        else if (position < 3) break;
        else setPosition(position - 3);
        break;
      //DOWN ARROW
      case 40:
        if(position === -1) break;
        else if (position > 8 && position < 12) {
          if(value.length === length) {
            onPinPadFocus(false);
        }
        } 
        else setPosition(position + 3);
        break;
      //ENTER
      case 13:
        if(position === -1) break;
        else if (position < 12) onKeyClick(buttonValues[position]);
        break;
      default:
        break;
    }
  }

  function onKeyClick(key: string) {
    if (key === deleteKey) {
      onValueChange('')
    } else if (key === backKey) {
      onValueChange(value.substring(0, value.length - 1))
    } else if (value.length < length) {
      onValueChange(value + key)
    }
  }

  return (
    <div className='pin-pad'>
      <div className='pin-code'>
        {Array.from({ length }, (_key, index) => {
          const digit = index < value.length ? value[index] : '_';
          return (
            <div key={index} className='pin-digit'>{digit}</div>
          );
        })}
      </div>
      <div className='key-pad'>
        {buttonValues.map((key, _index) => (
          <div
            key={key}
            className={ isPinPadFocus && position === _index ? "btn-keypad active" : "btn-keypad"}
            onMouseOver={() => { 
              setPosition(_index);
              onPinPadFocus(true);
            }}
            onClick={() => onKeyClick(key)}>
            {key === deleteKey && <Trash />}
            {key === backKey && <Backspace />}
            {key !== deleteKey && key !== backKey && <span>{key}</span>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PinPad