import { useEffect, useState } from "react";
import { combineLatest, distinctUntilChanged } from "rxjs";
import { ConnectStatus, ScreenType } from "../../misc/Constants";
import type SocketService from "../../services/SocketService";

export default interface RootViewModel {
  activeScreen: ScreenType;
}

export function RootViewModelFactory(
  { socketService }: { socketService: SocketService }
): RootViewModel {
  const [activeScreen, setScreen] = useState(ScreenType.Connect);

  useEffect(() => {
    // subcribe connect change
    const screenSubscription = combineLatest([
      socketService.watchConnect(),
      socketService.watchMedia()
    ], (connect, media) => {
      if (media != null) return ScreenType.Play;
      if (connect == ConnectStatus.Connected) return ScreenType.Idle;
      return ScreenType.Connect;
    }).pipe(distinctUntilChanged()).subscribe(setScreen);

    // dispose subcription
    return () => {
      screenSubscription.unsubscribe()
    };
  }, []); // Empty dependency array ensures that this effect runs only once

  return {
    activeScreen,
  };
}