import React from 'react';
import { HashRouter } from 'react-router-dom';
import './App.css';
import './misc/Extensions';
import './misc/Injections';
import RootView from './presentation/root/RootView';

function App() {
  return (
    <HashRouter>
      <RootView />
    </HashRouter>
  );
}

export default App;
