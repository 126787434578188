//import LS2Request from '@enact/webos/LS2Request';
//import deviceinfo from '@enact/webos/deviceinfo';
import { BehaviorSubject, Observable } from 'rxjs';
import { Constants } from '../misc/Constants';
import { productinfo, network } from 'tizen-tv-webapis';

export default interface DeviceService {
  watchIpAdress(): Observable<string | null>
  watchDeviceName(): Observable<string>
}

export class DeviceServiceImpl implements DeviceService {
  private ipAddressFlow = new BehaviorSubject<string | null>(null);
  private deviceNameFlow = new BehaviorSubject<string>("Web Browser");

  constructor() {
    if (Constants.isTV) {
      this.getIpAddressForTV();
      this.getDeviceNameForTV();
    } else {
      this.getDeviceNameForBrowser();
    }
  }

  watchIpAdress(): Observable<string | null> {
    return this.ipAddressFlow.asObservable();
  }

  watchDeviceName(): Observable<string> {
    return this.deviceNameFlow.asObservable();
  }

  private getDeviceNameForBrowser() {
    let browserName = 'Unknown Browser';
    if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
      browserName = 'Opera';
    } else if (navigator.userAgent.indexOf("Edg") !== -1) {
      browserName = 'Microsoft Edge';
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      browserName = 'Google Chrome';
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      browserName = 'Safari';
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      browserName = 'Mozilla Firefox';
    } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || !!document.documentElement) {
      browserName = 'Internet Explorer';
    }
    this.deviceNameFlow.next(browserName);
  }

  // Comment if not implement for WebOS
  // private getIpAddressForTV() {
  //   new LS2Request().send({
  //     service: 'luna://com.palm.connectionmanager',
  //     method: 'getStatus',
  //     onSuccess: (res: any) => {
  //       if (!res.isInternetConnectionAvailable) {
  //         return
  //       }
  //       let connected = 'connected'
  //       if (res.wifi.state === connected) {
  //         this.ipAddressFlow.next(res.wifi.ipAddress)
  //       } else if (res.wired.state === connected) {
  //         this.ipAddressFlow.next(res.wired.ipAddress)
  //       } else if (res.wifiDirect.state === connected) {
  //         this.ipAddressFlow.next(res.wifiDirect.ipAddress)
  //       }
  //     }
  //   });
  // }

  // private getDeviceNameForTV() {
  //   deviceinfo((info: any) => {
  //     this.deviceNameFlow.next(info.modelName)
  //   });
  // }

  // Comment if not implement for Tizen
  private getIpAddressForTV() {
    this.ipAddressFlow.next(network.getIp())
  }

  private getDeviceNameForTV() {
    this.deviceNameFlow.next("SamsungTV " + productinfo.getModel())
  }
}