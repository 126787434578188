export default class DeviceData {
  name: string;
  type: string;
  volume: number;
  capabilities: Capabilities;

  constructor(params: {
    name: string,
    type: string,
    volume: number,
    capabilities: Capabilities
  }) {
    this.name = params.name;
    this.type = params.type;
    this.volume = params.volume;
    this.capabilities = params.capabilities;
  }

  toJson(): string {
    return JSON.stringify(this);
  }
}

export class Capabilities {
  hasPlayState: boolean;
  hasPlayPause: boolean;
  hasDurationPosition: boolean;
  hasSeek: boolean;
  hasSpeed: boolean;
  hasRepeat: boolean;
  hasSubtitle: boolean;
  hasVolume: boolean;
  hasAudio: boolean;

  constructor(params: {
    hasPlayState: boolean,
    hasPlayPause: boolean,
    hasDurationPosition: boolean
    hasSeek: boolean
    hasSpeed: boolean
    hasRepeat: boolean
    hasSubtitle: boolean
    hasVolume: boolean
    hasAudio: boolean
  }) {
    this.hasPlayState = params.hasPlayState;
    this.hasPlayPause = params.hasPlayPause;
    this.hasDurationPosition = params.hasDurationPosition;
    this.hasSeek = params.hasSeek;
    this.hasSpeed = params.hasSpeed;
    this.hasRepeat = params.hasRepeat;
    this.hasSubtitle = params.hasSubtitle;
    this.hasVolume = params.hasVolume;
    this.hasAudio = params.hasAudio;
  }
}