declare global {
  interface String {
    last(): string;
    dropLast(): string;
  }
}

String.prototype.last = function () {
  return this[this.length - 1]
};

String.prototype.dropLast = function () {
  return this.slice(0, -1)
};

export { };